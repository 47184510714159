@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("montserrat-black.518e57c7.woff2") format("woff2"), url("montserrat-black.15224e66.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("montserrat-extra-bold.5a58940c.woff2") format("woff2"), url("montserrat-extra-bold.63e09a29.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("montserrat-bold.447a2a3e.woff2") format("woff2"), url("montserrat-bold.faadd583.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("montserrat-medium.3e1d2f02.woff2") format("woff2"), url("montserrat-medium.8e8ffb82.woff") format("woff");
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.45;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

html, body {
  background-color: #000;
  font-family: montserrat;
  font-size: .052vw;
  position: relative;
}

@media screen and (max-width: 999.9px) {
  html, body {
    font-size: .313vw;
  }
}

html {
  -webkit-tap-highlight-color: #0000;
}

body {
  max-width: 100vw;
  min-height: 800rem;
}

@media screen and (max-width: 999.9px) {
  body {
    min-height: auto;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: 8rem;
}

body {
  scrollbar-color: #45236f #000;
}

body::-webkit-scrollbar {
  width: 8rem;
}

body::-webkit-scrollbar-track {
  background: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: #45236f;
  border: none;
  border-radius: 20rem;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.button {
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10rem;
  font-family: montserrat, "sans-serif";
  font-size: 16rem;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  transition: all .4s ease-in-out;
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .button:hover, .button:focus {
    color: #fff;
  }
}

.button__icons {
  margin-right: 14rem;
  display: flex;
}

.button__icons > :not(:last-child) {
  margin-right: 11rem;
}

.button__icons img {
  width: 35rem;
}

.button--desktop-download {
  background-color: #363636;
  padding: 16rem 23rem 17rem 14rem;
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .button--desktop-download:hover, .button--desktop-download:focus {
    background-color: #434343;
  }
}

.button--login {
  background-color: #000;
  border: 1rem solid #fff;
  justify-content: center;
  min-width: 251rem;
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .button--login:hover, .button--login:focus {
    background-color: #1a1a1a;
  }
}

.button--freebet-circle {
  text-transform: uppercase;
  background-color: #753bbd;
  border-radius: 100rem;
  padding: 25rem 46rem;
  font-size: 35rem;
  font-weight: 700;
  box-shadow: 0 22rem 45rem #753bbd80;
}

@media screen and (min-width: 999.9px) {
  .button--freebet-circle:hover, .button--freebet-circle:focus {
    box-shadow: 0 22rem 35rem #753bbdb3;
  }
}

.button--freebet-parallelogram {
  text-transform: uppercase;
  cursor: pointer;
  padding: 25rem 46rem;
  font-size: 35rem;
  font-weight: 700;
  position: relative;
}

.button--freebet-parallelogram:before {
  content: "";
  z-index: -1;
  background-color: #753bbd;
  border-radius: 6rem;
  width: 100%;
  height: 100%;
  transition: all .4s ease-in-out;
  position: absolute;
  transform: skew(-18deg);
  box-shadow: 0 22rem 45rem #753bbd80;
}

@media screen and (min-width: 999.9px) {
  .button--freebet-parallelogram:hover:before, .button--freebet-parallelogram:focus:before {
    box-shadow: 0 22rem 35rem #753bbdb3;
  }
}

.button--rules {
  text-decoration: underline;
}

@media screen and (min-width: 999.9px) {
  .button--rules:hover, .button--rules:focus {
    opacity: .7;
  }
}

.button--signin {
  padding: 0;
  text-decoration: underline;
  display: inline-block;
}

@media screen and (min-width: 999.9px) {
  .button--signin:hover, .button--signin:focus {
    opacity: .7;
  }
}

@media screen and (max-width: 999.9px) {
  .button {
    font-size: 12rem;
  }

  .button--freebet-circle, .button--freebet-parallelogram {
    padding: 16rem 10rem;
    font-size: 23rem;
  }
}

.no-scroll {
  overflow: hidden;
}

.visually-hidden {
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.br-mobile {
  display: inline-block;
}

@media screen and (min-width: 999.9px) {
  .br-mobile {
    display: none;
  }
}

.header {
  z-index: 1;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header__logo {
  margin: 40rem 0 0 40rem;
}

.logo {
  width: 206rem;
  display: block;
}

.logo img {
  width: 100%;
}

.header__buttons {
  display: none;
}

.download-ios img, .download-android img {
  width: 38rem;
}

@media screen and (max-width: 999.9px) {
  .header {
    justify-content: space-between;
    align-items: center;
  }

  .header__logo {
    margin: 20rem 0 0 20rem;
  }

  .logo {
    width: 105rem;
  }

  .header__buttons {
    margin: 20rem 20rem 0 0;
    display: flex;
  }

  .header__buttons > :not(:last-child) {
    margin-right: 10rem;
  }
}

.promo {
  color: #fff;
  background-color: #06205c;
  background-image: url("bg-billboard.045b9ccd.webp");
  background-position: 0 100%;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  font-size: 16rem;
  display: flex;
  position: absolute;
  overflow: hidden;
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .promo {
    background-image: url("bg-billboard.230620dc.webp");
  }
}

.promo__billboard {
  flex-grow: 1;
}

.promo__aside {
  flex-basis: 43%;
}

@media screen and (max-width: 999.9px) {
  .promo {
    background-image: url("bg-mobile.3a261ed8.webp");
    background-position: top;
    background-size: cover;
    height: auto;
    padding: 60rem 20rem 38rem;
    position: relative;
  }
}

@media only screen and (max-width: 999.9px) and (min-device-pixel-ratio: 2), only screen and (max-width: 999.9px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 999.9px) and (min-resolution: 192dpi), only screen and (max-width: 999.9px) and (min-resolution: 2dppx) {
  .promo {
    background-image: url("bg-mobile.0b5cbf90.webp");
  }
}

@media screen and (max-width: 999.9px) {
  .promo__aside {
    flex-basis: 100%;
  }
}

.footer {
  z-index: 1;
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 77rem;
  padding: 0 60rem 7rem 40rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.payments {
  align-items: center;
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .payments > :not(:last-child) {
    margin-right: 30rem;
  }
}

.payments__visa {
  width: 47rem;
}

.payments__mc {
  width: 42rem;
}

.payments__peace {
  width: 57rem;
}

.payments__qiwi {
  width: 60rem;
}

.payments__pay {
  width: 52rem;
}

.payments__youmoney {
  width: 101rem;
}

.apps {
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .apps > :not(:last-child) {
    margin-right: 30rem;
  }
}

.app {
  width: 140rem;
  transition: all .4s ease-in-out;
}

.app img {
  width: 100%;
  display: block;
}

@media screen and (min-width: 999.9px) {
  .app:hover, .app:focus {
    opacity: .6;
  }
}

.soc {
  align-items: center;
  display: flex;
}

@media screen and (min-width: 999.9px) {
  .soc > :not(:last-child) {
    margin-right: 30rem;
  }
}

.soc__icon {
  width: 30rem;
  transition: all .4s ease-in-out;
}

.soc__icon img {
  width: 100%;
  display: block;
}

@media screen and (min-width: 999.9px) {
  .soc__icon:hover, .soc__icon:focus {
    opacity: .6;
  }
}

@media screen and (max-width: 999.9px) {
  .footer {
    flex-flow: column;
    height: auto;
    padding: 15rem 12rem;
    position: relative;
  }

  .payments {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20rem;
  }

  .payments__visa, .payments__mc {
    width: 30rem;
  }

  .payments__peace {
    width: 38rem;
  }

  .payments__qiwi, .payments__pay {
    width: 40rem;
  }

  .payments__youmoney {
    width: 90rem;
  }

  .apps {
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10rem;
  }

  .app {
    margin-bottom: 10rem;
  }

  .soc {
    width: 100%;
  }

  .soc__icon {
    width: 20rem;
  }

  .soc__icon:first-child {
    margin-right: auto;
  }

  .soc__icon:not(:first-child) {
    margin-left: 20rem;
  }
}

.billboard {
  position: relative;
}

.billboard__image {
  width: 100%;
  height: 100vh;
  min-height: 688rem;
  display: inline-block;
  position: absolute;
  top: 0;
}

@media screen and (min-width: 999.9px) {
  .billboard__image {
    opacity: 0;
  }
}

.billboard__image img, .billboard__image picture {
  object-fit: contain;
  object-position: center;
  height: 100%;
  margin-left: auto;
}

.billboard__description {
  width: 489rem;
  height: 100vh;
  max-height: 900rem;
  position: absolute;
  top: 50%;
  left: 40rem;
  transform: translateY(-50%);
}

.billboard__description svg {
  position: relative;
  top: 68%;
}

@media screen and (min-width: 999.9px) {
  .billboard__description {
    opacity: 0;
  }
}

.billboard__description img, .billboard__description picture {
  width: 100%;
}

.billboard__freebet-link {
  cursor: pointer;
  transition: all .3s ease-in;
}

.billboard__freebet-link:hover, .billboard__freebet-link:focus {
  opacity: .7;
}

@media screen and (max-width: 999.9px) {
  .billboard {
    display: none;
  }
}

.aside {
  z-index: 1;
  background-color: #00000080;
  flex-flow: column;
  align-items: stretch;
  padding: 40rem 70rem 110rem;
  display: flex;
  position: relative;
}

.aside__head {
  justify-content: center;
  display: flex;
}

.aside__head > :not(:last-child) {
  margin-right: 36rem;
}

.aside__body {
  flex-flow: column;
  justify-content: space-evenly;
  height: 100%;
  max-height: 800rem;
  margin: auto;
  padding-top: 38rem;
  display: flex;
}

.aside__stages {
  justify-content: center;
  width: 90%;
  height: 95rem;
  margin: 0 auto;
  display: flex;
}

.aside__stages img {
  height: 100%;
}

.aside__title {
  text-transform: uppercase;
  margin: 0 auto 15rem;
  font-size: 70rem;
  font-weight: 900;
  line-height: .9;
}

.aside__title sup {
  color: #00c7b1;
  font-size: 46rem;
  display: inline-block;
  position: relative;
  top: 8rem;
}

.aside__text {
  text-transform: uppercase;
  font-size: 30rem;
  font-weight: 900;
}

.aside__freebet {
  justify-content: center;
  width: 100%;
  height: 205rem;
  margin: 0 auto;
  display: none;
}

.aside__freebet img {
  height: 100%;
}

.aside__timer-wrapper {
  border: 1rem solid #fff;
  justify-content: center;
  align-items: center;
  width: 590rem;
  max-width: 100%;
  min-height: 74rem;
  margin: 0 auto 15rem;
  font-size: 30rem;
  display: flex;
}

.aside__timer {
  color: #00c7b1;
  font-weight: 700;
}

.aside__timer-text {
  text-transform: uppercase;
  padding-right: 10rem;
  display: inline-block;
}

.aside__time {
  text-align: center;
  padding: 0 5rem;
  display: inline-block;
}

.aside__time--seconds {
  min-width: 55rem;
  margin-left: -5rem;
}

.aside__buttons {
  flex-flow: column;
  margin: 0 auto;
  display: flex;
}

.aside__buttons .button {
  color: #000;
  margin: 0 auto;
  padding: 20rem 65rem;
}

.aside__buttons .button:first-child {
  margin-bottom: 20rem;
}

.aside__buttons .button:before {
  background-color: #fff;
  box-shadow: 0 16.5rem 36rem #ffffff61;
}

@media screen and (min-width: 999.9px) {
  .aside__buttons .button:hover:before, .aside__buttons .button:focus:before {
    box-shadow: 0 26rem 20rem #ffffff80;
  }
}

.aside__buttons .button--rules {
  color: #fff;
}

.aside__prize {
  max-width: 100%;
  margin-bottom: 15rem;
  display: flex;
}

.aside__prize-inner {
  display: flex;
}

.aside__prize-inner:first-child {
  margin-right: 10rem;
}

.aside__icon-wrap {
  width: 97rem;
  height: 54rem;
  margin-right: 2rem;
}

.aside__icon-wrap img {
  width: 100%;
  height: 100%;
}

.aside__prize-text {
  text-transform: uppercase;
  align-self: center;
  font-size: 14rem;
  font-weight: 700;
  line-height: 19rem;
}

.aside__prize-text span {
  color: #00c7b1;
}

#freebet {
  transform-origin: center;
  transform: scale(1.5);
}

#thousand {
  opacity: 0;
}

.aside__winners-wrapper {
  justify-content: center;
  width: 100%;
  display: flex;
}

.aside__winners {
  cursor: pointer;
  text-transform: uppercase;
  color: #00c7b1;
  background: none;
  border: none;
  outline: none;
  margin: 0 0 15rem;
  padding: 0;
  font-size: 22rem;
  font-weight: 700;
  display: inline-block;
}

.aside__winners:focus {
  outline: none;
}

.aside__winners:hover {
  opacity: .7;
}

.aside__winners:last-child {
  color: #fff;
}

.aside__winners:last-child .aside__winners-text:before {
  background-color: #fff;
}

.aside__winners--general {
  margin-right: 30rem;
}

.aside__winners-text {
  position: relative;
}

.aside__winners-text:before {
  content: "";
  background-color: #00c7b1;
  width: 100%;
  height: 3rem;
  position: absolute;
  bottom: -3rem;
  left: 0;
}

.asside__winners-arrow {
  width: 41rem;
  height: 19rem;
  display: inline-block;
  position: relative;
  top: 3rem;
  left: 4rem;
}

.asside__winners-arrow svg {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 999.9px) {
  .aside__buttons {
    order: 1;
  }
}

@media screen and (max-width: 999.9px) {
  .aside {
    background: none;
    margin-top: 15rem;
    padding: 0;
  }

  .aside__body {
    justify-content: flex-start;
    height: auto;
    max-height: none;
    padding-top: 5rem;
  }

  .aside__head {
    display: none;
  }

  .aside__title {
    margin-bottom: 28rem;
    font-size: 24rem;
  }

  .aside__title sup {
    font-size: 18rem;
    top: 3rem;
  }

  .aside__text {
    margin-bottom: 23rem;
    font-size: 25rem;
    line-height: 28rem;
  }

  .aside__stages, .aside__freebet {
    margin: 50rem auto;
    display: flex;
  }

  .aside__stages {
    height: 50rem;
  }

  .aside__freebet--link {
    order: 4;
  }

  .aside__freebet {
    height: 85rem;
    margin-top: 25rem;
    margin-bottom: 0;
  }

  .aside__prize {
    max-width: 100%;
    margin-bottom: 0;
    display: block;
  }

  .aside__prize-inner {
    margin-bottom: 20rem;
  }

  .aside__icon-wrap {
    flex-basis: 81.36rem;
    width: 81.36rem;
    height: 48.47rem;
    margin-right: 7rem;
  }

  .aside__prize-text {
    max-width: 190rem;
    font-size: 12rem;
    line-height: 1.25;
  }

  .aside__timer-wrapper {
    text-align: center;
    width: 100%;
    min-height: 69rem;
    margin-bottom: 22rem;
    padding-top: 7rem;
    font-size: 20rem;
    line-height: 1.3;
    display: block;
    position: relative;
  }

  .aside__timer-wrapper:before {
    content: "";
    background-image: url("players-mobile.331c5849.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 320rem;
    height: 460rem;
    bottom: 0;
    left: 0;
  }

  .aside__time {
    margin: 0;
    padding: 0;
  }

  .aside__time--seconds {
    min-width: 28rem;
  }

  .aside__mobile-bg {
    background-image: url("players-mobile.331c5849.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 320rem;
    height: 460rem;
    margin-bottom: 0;
    margin-left: -20rem;
    margin-right: -20rem;
    display: block;
  }

  .aside__buttons {
    order: 3;
  }

  .aside__buttons .button {
    width: 100%;
    padding: 13.5rem 29rem;
    font-size: 20rem;
  }

  .aside__buttons .button:before {
    width: 120%;
  }

  .aside__buttons .button:first-child {
    margin-bottom: 4rem;
  }

  .aside__buttons .button--rules {
    font-size: 12rem;
  }

  .aside__winners {
    margin-bottom: 32rem;
    font-size: 12rem;
  }

  .aside__winners-wrapper {
    justify-content: space-between;
  }

  .aside__winners--general {
    margin: 0 0 32rem;
  }

  .aside__winners-text:before {
    height: 2rem;
  }

  .asside__winners-arrow {
    width: 21rem;
    height: 10rem;
    top: 1rem;
  }
}

.popup {
  z-index: 10000;
  cursor: pointer;
  background-color: #000000c5;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.popup.popup-open {
  display: block;
}

.popup__content {
  cursor: auto;
  background: #fff;
  border-radius: 16rem;
  max-width: 800rem;
  padding: 16rem 32rem;
  font-size: 16rem;
}

.popup__text {
  scrollbar-color: #8f8f8f #e9e9e9;
  max-width: 800rem;
  max-height: 320rem;
  margin-top: 16rem;
  padding-top: 16rem;
  padding-right: 32rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.popup__text h3 {
  margin-top: 0;
}

.popup__text p {
  margin: 16rem 0;
}

.popup__text ul {
  list-style: none;
}

.popup__text::-webkit-scrollbar {
  width: 8px;
}

.popup__text::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.popup__text::-webkit-scrollbar-thumb {
  background-color: #8f8f8f;
  border: none;
  border-radius: 20px;
}

.popup__container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.simplebar-placeholder {
  display: none;
}

.popup__head {
  border-bottom: .5px solid gray;
  padding: 16rem 0;
  position: relative;
}

.popup__head p {
  margin: 0;
}

.popup__close-button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 32rem;
  height: 32rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.popup__close-button:focus {
  outline: none;
}

.popup__close-button:hover {
  opacity: .8;
}

.popup__close-button:before, .popup__close-button:after {
  content: "";
  background: #000;
  width: 100%;
  height: 3.2rem;
  position: absolute;
  top: 50%;
  left: 0;
}

.popup__close-button:before {
  transform: rotate(45deg);
}

.popup__close-button:after {
  transform: rotate(135deg);
}

#stock .popup__text h3 {
  text-transform: uppercase;
}

#stock .popup__text ul {
  margin-top: -15rem;
  list-style: disc;
}

@media screen and (max-width: 999.9px) {
  .popup__content {
    border-radius: 0;
    max-width: 100%;
    height: 100%;
    padding: 16rem 15rem 0;
    font-size: 12rem;
  }

  .popup__head {
    padding-right: 20rem;
  }

  .popup__text {
    max-height: calc(100% - 100rem);
    padding: 0 15rem 0 0;
  }

  .popup__close-button {
    width: 15rem;
    top: 0;
    transform: none;
  }

  .popup__close-button:before, .popup__close-button:after {
    height: 2rem;
  }
}

.popup-dark {
  z-index: 1000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.popup-dark--active {
  display: block;
}

.success .popup-dark__btn-close {
  top: -126rem;
}

.popup-dark__title {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 50rem auto 35rem;
  font-size: 24rem;
  font-weight: 900;
  line-height: 29rem;
}

.popup-dark__content {
  z-index: 2;
  background-color: #000;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10rem 14rem 10rem 27rem;
  top: 50%;
  left: 50%;
}

.popup-dark__overlay {
  z-index: 1;
  background-color: #000;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 25rem;
  padding-bottom: 50px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.popup-dark__container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.popup-dark__btn-close {
  z-index: 2;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  width: 22rem;
  height: 22rem;
  margin: 0;
  padding: 25rem;
  position: absolute;
  top: -16rem;
  right: 9rem;
}

.popup-dark__btn-close:before, .popup-dark__btn-close:after {
  content: "";
  background-color: #fff;
  width: 3rem;
  height: 31rem;
  position: absolute;
  top: 8rem;
  left: 23rem;
}

.popup-dark__btn-close:before {
  transform: rotate(45deg);
}

.popup-dark__btn-close:after {
  transform: rotate(-45deg);
}

.popup-dark__reg {
  text-align: center;
  color: #fff;
  margin: 0 auto;
  font-size: 14rem;
  font-weight: 500;
  line-height: 17rem;
  text-decoration-line: underline;
  display: block;
}

.popup-dark__btn {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #0000;
  border: 0;
  width: 100%;
  height: 60rem;
  margin: 0 auto 26rem;
  font-size: 20rem;
  font-weight: 700;
  line-height: 24rem;
  position: relative;
}

.popup-dark__btn:before {
  content: "";
  z-index: -1;
  background-color: #00c7b1;
  border-radius: 6.6526rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-18deg);
  box-shadow: 0 0 8rem #fff6, 0 0 30rem #00c7b1;
}

.popup-dark__text {
  color: #fff;
  text-align: center;
  margin-bottom: 20rem;
  font-size: 16rem;
  font-weight: 500;
  line-height: 19rem;
}

@media screen and (min-width: 999.9px) {
  .popup-dark__overlay {
    background-color: #0009;
    width: 100%;
    height: 100%;
    min-height: 800rem;
    padding-top: 17rem;
    padding-bottom: 0;
  }

  .popup-dark__container {
    background-color: #000;
    width: 828rem;
    height: 677rem;
  }

  .popup-dark__content {
    border-radius: 15rem;
    margin: 0;
    padding-top: 50rem;
    padding-left: 70rem;
    padding-right: 70rem;
  }

  .popup-dark__title {
    max-width: 100%;
    margin-top: 10rem;
    margin-bottom: 61rem;
    font-size: 50rem;
    line-height: 61rem;
  }

  .popup-dark__btn-close {
    top: 19rem;
    right: 16rem;
  }

  .popup-dark__btn-close:before, .popup-dark__btn-close:after {
    width: 1rem;
  }

  .popup-dark__btn {
    width: 272rem;
    height: 60rem;
    font-size: 24.7097rem;
    line-height: 30rem;
  }

  .popup-dark__reg {
    font-size: 16rem;
    line-height: 20rem;
  }

  .success .popup-dark__content {
    padding-top: 109rem;
  }

  .success .popup-dark__container {
    height: 268rem;
  }

  .success .popup-dark__btn-close {
    top: 24rem;
  }

  .popup-dark__text {
    margin-bottom: 40rem;
    font-size: 33rem;
    line-height: 40rem;
  }
}

.form {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.form__input-count {
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  text-align: center;
  background-color: #0000;
  border: none;
  outline: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 43rem;
  display: inline-block;
}

.form__input-count::placeholder {
  color: #fff;
  opacity: 1;
}

.form__input-count::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form__input-count::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form__counter {
  justify-content: center;
  margin-left: 20rem;
  display: flex;
}

.form__counter-buttons {
  flex-direction: column;
  display: flex;
}

.form__number {
  text-align: center;
  width: 111.4rem;
  height: 78.78rem;
  display: block;
  position: relative;
  right: 12rem;
}

.form__number:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background-image: url("counter.c2253182.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.form__counter-buttons {
  margin-bottom: 15rem;
}

.form__counter-button {
  cursor: pointer;
  z-index: 1;
  background: url("arrow-up.deeb412c.svg") 0 0 / contain no-repeat;
  border: none;
  outline: none;
  width: 52.8rem;
  height: 37.3rem;
  margin: 0;
  padding: 0;
}

.form__counter-button:focus {
  outline: none;
}

.form__counter-button:active {
  transform: scale(.8);
}

.form__counter-button:last-child {
  margin-top: 3rem;
  position: relative;
  right: 12rem;
  transform: scale(-1);
}

.form__counter-button:last-child:active {
  transform: scale(-1)scale(.8);
}

.form__counter-title {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 20rem;
  font-weight: 700;
}

.form__vs {
  color: #00c7b1;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 10rem;
  font-size: 43rem;
  font-weight: 900;
}

.form__counter--second .form__counter-buttons {
  margin-left: -11rem;
}

.form__btn {
  color: #000;
  width: 95%;
  margin: 30rem 0;
  padding: 0;
}

.form__btn:before {
  background-color: #fff;
  box-shadow: 0 16.5rem 36rem #ffffff61;
}

.form__tel {
  width: 270rem;
  height: 51rem;
  margin-bottom: 15rem;
  position: relative;
}

.form__tel:before {
  content: "";
  pointer-events: none;
  border: 2rem solid #fff;
  border-radius: 6.51513rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-18deg);
}

.form__tel--error:before {
  border-color: #dc143c;
  box-shadow: 0 0 45rem #dc143c66;
}

.form__input-tel {
  color: #fff;
  background: none;
  border: 0;
  outline: none;
  width: 287rem;
  height: 51rem;
  margin: 0;
  padding: 15rem 0 17rem 20rem;
  font-size: 16rem;
  font-weight: 500;
  line-height: 20rem;
}

.form__input-tel::placeholder {
  color: #ffffff4d;
  font-size: 14rem;
  font-weight: 500;
  line-height: 17rem;
}

.form__agree {
  color: #ffffff4d;
  margin-bottom: 40rem;
  padding-left: 10rem;
  padding-right: 10rem;
  font-size: 11rem;
}

.form__agree a {
  color: #ffffff4d;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form__checkbox + label {
  -webkit-user-select: none;
  user-select: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.form__checkbox {
  z-index: -1;
  opacity: 0;
  width: 15rem;
  height: 15rem;
  position: absolute;
}

.form__checkbox + label:after, .form__checkbox + label:before {
  content: "";
  display: inline-block;
}

.form__checkbox + label:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 1rem solid #9e9e9e;
  border-radius: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 18rem;
  height: 18rem;
  margin-top: 3.2rem;
  transition: box-shadow .3s;
}

.form__checkbox + label:after {
  background: #00c7b1;
  width: 13rem;
  height: 12rem;
  transition: transform .3s;
  position: absolute;
  top: 6.33rem;
  left: 2.4888rem;
  transform: scale(0);
}

.form__checkbox:checked + label:after {
  transform: scale(1);
}

.form__check {
  margin-bottom: 31rem;
}

.form__check label {
  margin-top: 41rem;
  margin-left: -4rem;
}

.form__wrapper--repeat {
  align-items: center;
  height: 85%;
  display: flex;
}

.form__wrapper--repeat h2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form__wrapper--result .popup-dark__title {
  margin-bottom: 10rem;
}

@media screen and (min-width: 999.9px) {
  .form__score {
    justify-content: space-between;
    display: flex;
  }

  .form__counter-button {
    width: 85.8rem;
    height: 60.6rem;
  }

  .form__counter-button:last-child {
    right: 18rem;
  }

  .form__number {
    width: 181rem;
    height: 128rem;
  }

  .form__number:before {
    width: 100%;
    height: 100%;
  }

  .form__input-count {
    font-size: 70rem;
  }

  .form__vs {
    align-self: center;
    margin-bottom: 37rem;
    font-size: 70rem;
  }

  .form__btn {
    width: auto;
    margin: 62rem auto 0;
    padding: 41rem 60rem;
    font-size: 30rem;
  }

  .form__btn:hover {
    color: #000;
  }

  .form__btn:hover:before {
    box-shadow: 0 23rem 51rem #ffffff61;
  }

  .form__tel {
    width: 100%;
    min-height: 72rem;
  }

  .form__input-tel {
    width: 100%;
    height: 100%;
    font-size: 25rem;
  }

  .form__input-tel::placeholder {
    font-size: 25rem;
    line-height: 29rem;
  }

  .form__agree {
    font-size: 20rem;
  }

  .form__check label:before {
    width: 23rem;
    height: 23rem;
    margin-top: 7rem;
  }

  .form__check label:after {
    border-radius: 3rem;
    width: 19rem;
    height: 19rem;
    top: 9rem;
    left: 2rem;
  }

  .form__freebet {
    width: 460rem;
    height: 120rem;
    margin: 0 auto 100rem;
  }

  .form__wrapper--result .popup-dark__title {
    margin-bottom: 30rem;
  }

  .form__wrapper--result .form__btn {
    color: #000;
    max-width: 467rem;
    display: flex;
  }

  .form__wrapper--timer-expired {
    margin-top: 170rem;
  }
}

.winners {
  color: #fff;
  font-size: 25rem;
  font-weight: 500;
}

.winners .popup-dark__title {
  margin-top: 25rem;
}

.winners__list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: 400rem;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
}

.winners__list::-webkit-scrollbar {
  display: none;
}

.winners__item {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}

.winners__info {
  text-align: right;
}

.winners__name {
  pointer-events: none;
}

.winners__wrapper {
  display: none;
}

.winners__wrapper.is-active {
  display: block;
}

.winners__wrapper--match .popup-dark__title {
  margin-bottom: 8rem;
}

.winners__wrapper--match .winners__item:first-child {
  color: #00c7b1;
}

.winners__match-name {
  text-align: center;
  margin-bottom: 50rem;
}

.winners__btn-back {
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0 0 0 25rem;
  font-size: 22rem;
  font-weight: 500;
  position: absolute;
  top: 30rem;
  left: 40rem;
}

.winners__btn-back:focus {
  outline: none;
}

.winners__btn-back:before {
  content: "";
  border-top: none;
  border-bottom: 3rem solid #fff;
  border-left: 3rem solid #fff;
  border-right: none;
  width: 17rem;
  height: 17rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)rotate(45deg);
}

@media screen and (min-width: 999.9px) {
  .winners__btn-back:hover {
    color: #00c7b1;
  }

  .winners__btn-back:hover:before {
    border-color: #00c7b1;
  }

  .winners__wrapper--list .winners__item:hover {
    color: #00c7b1;
  }
}

@media screen and (max-width: 999.9px) {
  .winners {
    min-height: 100vh;
    font-size: 18rem;
  }

  .winners .popup-dark__title {
    margin: 50rem auto 35rem;
  }

  .winners__item {
    margin-bottom: 20rem;
  }

  .winners__wrapper--match .winners__name {
    margin-right: 10rem;
  }

  .winners__btn-back {
    font-size: 17rem;
    top: -5rem;
    left: 30rem;
  }

  .winners__btn-back:before {
    width: 15rem;
    height: 15rem;
  }
}

.popup-dark--forecast-result .popup-dark__container {
  width: 1152rem;
}

.winners__wrapper--forecast {
  display: block;
}

.forecast__list {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 25rem;
  font-weight: 500;
  list-style: none;
}

.forecast__item {
  margin-bottom: 20rem;
  font-size: 17rem;
  display: flex;
}

.forecast__item--main {
  display: none;
}

.forecast__name {
  order: 2;
  width: 180rem;
  margin-right: 10rem;
}

.forecast__data {
  text-align: center;
  order: 1;
  margin-right: 19rem;
}

.forecast__info {
  text-align: right;
  order: 3;
}

@media screen and (min-width: 999.9px) {
  .forecast__item {
    font-size: 25rem;
    line-height: 45rem;
  }

  .forecast__item--main {
    text-transform: uppercase;
    color: #00c7b1;
    font-size: 25rem;
    font-weight: 700;
    line-height: 50rem;
    display: flex;
  }

  .forecast__name {
    order: 1;
    width: 530rem;
  }

  .forecast__data {
    text-align: center;
    width: 187rem;
  }

  .forecast__info {
    text-align: right;
    width: 284rem;
  }
}

.popup-dark--plug {
  display: block;
}

.popup-dark--plug .popup-dark__title {
  text-align: center;
  text-transform: uppercase;
  max-width: 729rem;
  margin-bottom: 42rem;
  font-size: 46rem;
  font-weight: 900;
  line-height: 56rem;
}

.popup-dark--plug .popup-dark__title span {
  color: #00c7b1;
  text-transform: none;
}

.popup-dark--plug .popup-dark__container {
  height: 605rem;
}

.popup-dark--plug .popup-dark__content {
  padding-top: 80rem;
  padding-left: 50rem;
  padding-right: 50rem;
}

.popup-dark--plug .popup-dark__text {
  margin-bottom: 27rem;
  font-size: 24rem;
  font-weight: 400;
  line-height: 34rem;
}

.popup-dark--plug .popup-dark__btn-wrapper {
  flex-direction: column;
  display: flex;
}

.popup-dark--plug .popup-dark__btn {
  color: #000;
  justify-content: center;
  align-items: center;
  width: 467rem;
  min-height: 78rem;
  font-size: 28.3062rem;
  font-weight: 700;
  line-height: 35rem;
  text-decoration: none;
  display: flex;
}

.popup-dark--plug .popup-dark__btn:before {
  background-color: #fff;
  border-radius: 9.8526rem;
  box-shadow: 0 14.1944rem 30.8573rem #ffffff80;
}

.popup-dark--plug .popup-dark__btn--lazur:before {
  background-color: #00c7b1;
  border-radius: 9.8526rem;
  box-shadow: 0 14.1944rem 30.8573rem #00c7b180;
}

@media screen and (max-width: 999.9px) {
  .popup-dark--plug .popup-dark__title {
    margin-bottom: 47rem;
    font-size: 23rem;
    line-height: 28rem;
  }

  .popup-dark--plug .popup-dark__text {
    margin-bottom: 31rem;
    font-size: 12rem;
    line-height: 14rem;
  }

  .popup-dark--plug .popup-dark__btn {
    width: 100%;
    min-height: 57rem;
    font-size: 18rem;
    line-height: 22rem;
  }

  .popup-dark--plug .popup-dark__container {
    height: 100%;
  }

  .popup-dark--plug .popup-dark__content {
    padding-top: 0;
    padding-left: 17rem;
    padding-right: 17rem;
  }
}

.popup-promotion-end {
  z-index: 10000;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.popup-promotion-end--close {
  display: none;
}

.popup-promotion-end--open {
  display: block;
}

.popup-promotion-end__container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-promotion-end__overlay {
  z-index: 1;
  background-color: #00000080;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 20rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.popup-promotion-end__content {
  cursor: auto;
  background-color: #000;
  max-width: 1016rem;
  padding: 0 0 136rem;
}

.popup-promotion-end__head {
  position: relative;
}

.popup-promotion-end__close-button {
  cursor: pointer;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 32rem;
  height: 32rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 20rem;
  right: 20rem;
}

.popup-promotion-end__close-button:focus {
  outline: none;
}

.popup-promotion-end__close-button:hover {
  opacity: .8;
}

.popup-promotion-end__close-button:before, .popup-promotion-end__close-button:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 3.2rem;
  position: absolute;
  top: 50%;
  left: 0;
}

.popup-promotion-end__close-button:before {
  transform: rotate(45deg);
}

.popup-promotion-end__close-button:after {
  transform: rotate(135deg);
}

.popup-promotion-end__title {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 61rem;
  padding-top: 136rem;
  padding-left: 136rem;
  padding-right: 136rem;
  font-size: 95rem;
  font-weight: 900;
  line-height: 100%;
}

.popup-promotion-end__description {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding-left: 136rem;
  padding-right: 136rem;
  font-size: 43rem;
  font-weight: 700;
  line-height: normal;
}

.popup-promotion-end__description span {
  color: #00c7b1;
}

@media screen and (max-width: 999.9px) {
  .popup-promotion-end__container {
    height: 100vh;
    position: relative;
  }

  .popup-promotion-end__content {
    max-width: 100%;
    height: 100%;
    padding: 16rem 15rem 0;
  }

  .popup-promotion-end__overlay {
    background-color: #000;
    padding: 0;
  }

  .popup-promotion-end__title {
    margin-bottom: 43rem;
    padding: 129rem 0 0;
    font-size: 38rem;
  }

  .popup-promotion-end__description {
    padding: 0;
    font-size: 23rem;
  }

  .popup-promotion-end__close-button {
    width: 15rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .popup-promotion-end__close-button:before, .popup-promotion-end__close-button:after {
    height: 2rem;
  }
}

/*# sourceMappingURL=index.fcae0f0e.css.map */
